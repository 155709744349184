import React, { useEffect, useRef } from "react";
import { render } from "../services/shader/shaderProcess";

type GlassShaderProps = {
  image: HTMLImageElement | null;
  bumpMap: HTMLImageElement | null;
};

const GlassShader: React.FC<GlassShaderProps> = ({ image, bumpMap }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null && image !== null && bumpMap !== null) {
      // resize canvas to match image
      canvas.width = image.width;
      canvas.height = image.height;
      // render the shader program
      render(canvas, image, bumpMap);
    }
  }, [canvasRef, image, bumpMap]);
  return <canvas ref={canvasRef} />;
};

export default GlassShader;
