import styled from "styled-components";
import { InputType } from "../utils/bumpmapVariables";
import NumberInput from "./NumberInput";
import React, { FC } from "react";
import { BumpSettingsType } from "../types/types";

const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const GroupHeading = styled.div`
  padding: 20px 0 0 10px;
  font-weight: 700;
  font-size: 14px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
`;

function createRowInputs(
  rowData: InputType[],
  handleChange: (label: string, value: number) => void,
  settings: BumpSettingsType
) {
  const row = rowData.map((data) => {
    let label: string = data.variable;
    if (data.label) label = data.label;
    return (
      <NumberInput
        label={label}
        key={data.variable}
        initialValue={settings[data.variable]}
        changeCallback={(value) => {
          handleChange(data.variable, value);
        }}
      />
    );
  });
  return <InputsRow>{row}</InputsRow>;
}

type GroupedInputsProps = {
  heading: string;
  inputs: InputType[];
  handleChange: (label: string, value: number) => void;
  settings: BumpSettingsType;
};

const GroupedInputs: FC<GroupedInputsProps> = ({
  heading,
  inputs,
  handleChange,
  settings,
}) => {
  return (
    <div>
      <GroupHeading>{heading}</GroupHeading>
      {createRowInputs(inputs, handleChange, settings)}
    </div>
  );
};

export default GroupedInputs;
