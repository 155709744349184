import React, { FC, useEffect, useRef } from "react";
import StyledCanvas from "./StyledCanvas";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { UIEventCallbackFunc } from "../types/types";

type ImageHolderProps = {
  name: string;
  image: HTMLImageElement;
  id: number;
  callback: UIEventCallbackFunc | null;
};

type BottomBoxProps = {
  width: string;
};

const BottomBox = styled.div<BottomBoxProps>`
  width: ${(props) => props.width};
  height: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const Container = styled.div`
  margin: 0 1rem 0 0;
  padding: 0;
  background-color: teal;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
`;

const LeftSide = styled.div`
  padding-left: 0.5em;
  overflow: hidden;
  max-height: 1.7em;
  max-width: 80%;
`;

const ImageHolder: FC<ImageHolderProps> = ({ name, image, id, callback }) => {
  const height = 170;
  const scale = height / image.height;
  const displayWidth = `${image.width * scale}px`;
  const displayHeight = `${height}px`;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null) {
      const ctx = canvas.getContext("2d");
      if (ctx != null) {
        ctx.drawImage(image, 0, 0);
      }
    }
  }, [image]);

  function handleDeleteClicked() {
    if (callback !== null) callback("DELETE_IMAGE", id);
  }

  function handleEditClicked() {
    if (callback !== null) callback("EDIT_IMAGE", id);
  }

  return (
    <Container>
      <StyledCanvas
        ref={canvasRef}
        width={image.width}
        height={image.height}
        displayWidth={displayWidth}
        displayHeight={displayHeight}
      />
      <BottomBox width={displayWidth}>
        <LeftSide>{name}</LeftSide>
        <div>
          <Icon name="sliders horizontal" onClick={handleEditClicked} />
          <Icon name="window close" onClick={handleDeleteClicked} />
        </div>
      </BottomBox>
    </Container>
  );
};

export default ImageHolder;
