export function initGL(
  canvas: HTMLCanvasElement
): WebGLRenderingContext | null {
  // preserveDrawingBuffer:true
  // means that the image is
  // downloadable from the webpage
  const gl: WebGLRenderingContext | null = canvas.getContext("webgl", {
    preserveDrawingBuffer: true,
  });
  if (!gl) {
    alert("No webgl for you!");
    return null;
  }
  return gl;
}

export function createShader(
  gl: WebGLRenderingContext,
  type: number,
  source: string
) {
  const shader: WebGLShader | null = gl.createShader(type);
  if (shader !== null) {
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (success) {
      return shader;
    }
    // console.log(gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
  }
}

export function createProgram(
  gl: WebGLRenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
) :WebGLProgram | null {
  const program: WebGLProgram | null = gl.createProgram();
  if (program === null) return null;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) {
    return program;
  }
  console.log("Failed to Build Shader Program!!")
  console.log(gl.getProgramInfoLog(program));
  gl.deleteProgram(program);
  return null;
}

export function resizeCanvasToDisplaySize(canvas: HTMLCanvasElement): boolean {
  // Lookup the size the browser is displaying the canvas in CSS pixels.
  const displayWidth = canvas.clientWidth;
  const displayHeight = canvas.clientHeight;
  // Check if the canvas is not the same size.
  const needResize =
    canvas.width !== displayWidth || canvas.height !== displayHeight;
  if (needResize) {
    // Make the canvas the same size
    canvas.width = displayWidth;
    canvas.height = displayHeight;
  }
  return needResize;
}
