export const fragmentShaderSrc = `
precision mediump float;

// our textures
uniform sampler2D uImageTexture;
uniform sampler2D uBumpTexture;

// the texCoords passed in from the vertex shader.
varying vec2 v_texCoord;
varying vec2 v_aux;

void main() {
   // vec4 color0 = texture2D(uImageTexture, v_texCoord);
   // vec4 color1 = texture2D(uBumpTexture, v_aux); // v_texCoord);
   // gl_FragColor = color0 * color1;
   
   vec4 u_color = vec4(1,1,1,1);
   vec2 normal = texture2D (uBumpTexture, v_aux).rg;

   normal.x -= 0.498039216;
   normal.y -= 0.498039216;

   gl_FragColor = texture2D (uImageTexture,  v_texCoord + normal) * u_color;
   gl_FragColor.a *= texture2D (uBumpTexture, v_aux).a;
}
`