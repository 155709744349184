import React, { FC, useContext } from "react";
import ImageEditor from "./ImageEditor";
import Context from "../state/Context";
import { ImageDataOrNull, StateMessageType } from "../types/types";

type ImageEditorControllerProps = {
  image: ImageDataOrNull;
};

const ImageEditorController: FC<ImageEditorControllerProps> = ({ image }) => {
  const state = useContext(Context);

  function handleMessage(type: StateMessageType, payload: any) {
    if (state !== null) {
      state.message(type, payload);
    }
  }

  if (image === null) return null;
  return <ImageEditor image={image} sendMessage={handleMessage} />;
};

export default ImageEditorController;
