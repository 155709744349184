import Amplify, { API } from "aws-amplify";
import awsconfig from "../../aws-exports";
Amplify.configure(awsconfig);

const path = "/api/fetchimage";
const apiName = "shadyFetchImager";

export function fetchData() {
  API.get(apiName, path, null)
    .then((response) => {
      console.log("Response", response);
    })
    .catch((err) => {
      console.log("err", err);
    });
}

/*
import axios from "axios";

export function fetchData() {
  axios
    .get("/api/fetchimage")
    .then((response) => {
      console.log("RESPONSE:", response);
    })
    .catch((err) => {
      console.log("ERR:", err);
    });
}
*/
