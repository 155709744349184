import styled from "styled-components";

type CanvasProps = {
    displayWidth: string;
    displayHeight: string;
};

const StyledCanvas = styled.canvas<CanvasProps>`
  background-color: darkgrey;
  height: ${({ displayHeight }: CanvasProps) => displayHeight};
  width: ${({ displayWidth }: CanvasProps) => displayWidth};
  margin: 0;
  padding: 0;
`;

export default StyledCanvas;