/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "fetchImage",
            "endpoint": "https://4lzqejkmq5.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "shadyImageGrabber",
            "endpoint": "https://63zqdjvpk7.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
