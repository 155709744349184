import { Observable } from "rxjs";

export function loadImageFromFile(file: File): Observable<HTMLImageElement> {
  return new Observable((observer) => {
    const uri = URL.createObjectURL(file);
    const image = new Image();
    image.onload = () => {
      URL.revokeObjectURL(uri);
      observer.next(image);
      observer.complete();
    };
    image.onerror = (err) => {
      observer.error(err);
    };
    image.src = uri;
  });
}

export function loadImageFromUrl(url: string): Observable<HTMLImageElement> {
  return new Observable((observer) => {
    const image = new Image();
    image.onload = () => {
      observer.next(image);
      observer.complete();
    };
    image.onerror = (err) => {
      observer.error(err);
    };
    image.src = url;
  });
}

export function createImageFromCanvas(
  canvas: HTMLCanvasElement
): Observable<HTMLImageElement> {
  return new Observable((observer) => {
    const image = new Image();
    image.onload = () => {
      observer.next(image);
      observer.complete();
    };
    image.onerror = (err) => {
      observer.error(err);
    };
    const dataURL = canvas.toDataURL("image/png");
    image.src = dataURL;
  });
}
