import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { ImageData, StateMessageType } from "../types/types";
import { loadImageFromFile } from "../utils/imageLoader";
import { getBumpMapDefaults } from "../utils/bumpmapVariables";

class State {
  private _id: number = 0;
  private _fullImageList: ImageData[] = [];
  private _editIdStream$: BehaviorSubject<number> = new BehaviorSubject<number>(
    -1
  );
  private _imageList$: BehaviorSubject<ImageData[]> = new BehaviorSubject<
    ImageData[]
  >([]);

  addToFiles(files: File[]) {
    files.forEach((file) => {
      loadImageFromFile(file).subscribe((image) => {
        const id = this._id++;
        this._fullImageList.push({
          file,
          image,
          removed: false,
          id,
          settings: getBumpMapDefaults(),
        });
        this._imageList$.next(this._fullImageList);
      });
    });
  }

  removeFile(id: number) {
    this._fullImageList = this._fullImageList.filter(
      (image) => image.id !== id
    );
    this._imageList$.next(this._fullImageList);
  }

  private _editImage$: Observable<ImageData | null> = combineLatest(
    this._editIdStream$,
    this._imageList$
  ).pipe(
    map(([id, images]) => {
      const image: ImageData[] = images.filter((i) => i.id === id);
      if (image.length < 1) return null;
      return image[0];
    })
  );

  message(type: StateMessageType, payload: any) {
    // console.log("MESSAGE", type, payload);
    switch (type) {
      case "ADD_FILES":
        this.addToFiles(payload);
        break;
      case "DELETE_IMAGE":
        this.removeFile(payload);
        break;
      case "EDIT_IMAGE":
        this._editIdStream$.next(payload);
        break;
      case "EXIT_EDITOR":
        this._editIdStream$.next(-1);
        break;
    }
  }

  public get imageList() {
    return this._imageList$;
  }

  public get editableImage(): Observable<ImageData | null> {
    return this._editImage$;
  }
}

export default State;
