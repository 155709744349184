import React, { ChangeEvent, useState } from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";

const FieldContainer = styled.div`
  padding: 10px;
`;

type NumberInputProps = {
  label: string;
  changeCallback?: (value: number) => void;
  width?: string | number;
  initialValue: number;
};

const defaultProps = {
  label: "",
  changeCallback: () => {},
  width: "70px",
  initialValue: 100,
};

const NumberInput: React.FC<NumberInputProps> = ({
  label,
  changeCallback,
  width,
  initialValue,
}) => {
  const [value, setValue] = useState<number>(initialValue);
  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    const newValue = parseInt(e.target.value);
    setValue(newValue);
    if (typeof changeCallback === "function") {
      changeCallback(newValue);
    }
  }
  let widthStr: string = "";
  switch (typeof width) {
    case "number":
      widthStr = width + "px";
      break;
    case "string":
      widthStr = width as string;
      break;
    default:
      widthStr = "100px";
  }
  return (
    <FieldContainer>
      <Form.Field style={{ width: widthStr, fontSize: "0.8em" }}>
        <label>{label}</label>
        <input type="number" onChange={handleChange} value={value.toString()} />
      </Form.Field>
    </FieldContainer>
  );
};

NumberInput.defaultProps = defaultProps;

export default NumberInput;
