import React from "react";
import { bumpMapVariables, InputGroup } from "../utils/bumpmapVariables";
import GroupedInputs from "./GroupedInputs";
import { Form } from "semantic-ui-react";
import { BumpSettingsType } from "../types/types";

type ImageEditControlsProps = {
  updateCallback: (settings: BumpSettingsType) => void;
  settings: BumpSettingsType;
};

const ImageEditControls: React.FC<ImageEditControlsProps> = ({
  updateCallback,
  settings,
}) => {
  function createInputs() {
    return bumpMapVariables.map((groupData: InputGroup, idx) => {
      const heading: string = groupData.group;
      return (
        <GroupedInputs
          heading={heading}
          inputs={groupData.inputs}
          handleChange={handleChange}
          key={idx}
          settings={settings}
        />
      );
    });
  }
  function handleChange(variable: string, value: number): void {
    // console.log(variable, value);
    settings[variable] = value;
    updateCallback(settings);
  }

  return (
    <div>
      <Form>{createInputs()} </Form>
    </div>
  );
};

export default ImageEditControls;
