import React, { FC, useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import ImageEditControls from "./ImageEditControls";
import { BumpSettingsType } from "../types/types";

import { normalsGenerator } from "../services/bumpMap/bumpMapGenerator";
import StyledCanvas from "./StyledCanvas";
import GlassShader from "./GlassShader";
import { createImageFromCanvas } from "../utils/imageLoader";
import { StateMessageType, ImageData } from "../types/types";
import VisualSettings from "./VisualSettings";

type ImageEditorProps = {
  image: ImageData;
  sendMessage: (type: StateMessageType, payload: any) => void;
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  border: 2px solid black;
  margin: 10px;
  padding: 20px 20px 20px 20px;
  overflow: auto;
  flex-direction: column;
`;

const IconHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 20px;
  font-size: 24px;
  margin: 5px 0 20px 0;
`;

const ImageEditor: FC<ImageEditorProps> = ({ image, sendMessage }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const bumpMapRef = useRef<HTMLCanvasElement | null>(null);
  const [settings, setSettings] = useState<BumpSettingsType>(image.settings);

  const [bumpMapWidth, setBumpMapWidth] = useState<number>(
    image.settings.width
  );
  const [bumpMapHeight, setBumpMapHeight] = useState<number>(
    image.settings.height
  );

  const [bumpMapImage, setBumpMapImage] = useState<HTMLImageElement | null>(
    null
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null && image !== null) {
      if (image.image !== null) {
        canvas.width = image.image.width;
        canvas.height = image.image.height;
        const ctx = canvas.getContext("2d");
        if (ctx != null) {
          ctx.drawImage(image.image, 0, 0);
        }
        updateBitmaps();
      }
    }

    const bumpMapCanvas: HTMLCanvasElement | null = bumpMapRef.current;
    if (bumpMapCanvas !== null) {
      createImageFromCanvas(bumpMapCanvas).subscribe((bumpMapImage) => {
        setBumpMapImage(bumpMapImage);
      });
    }

    // Match filter to image
    /*

    //TODO More work todo here
    // Update the settings panel
    // Give a choice whether to update

    const settings: BumpSettingsType = {...image.settings}
    if(image.image?.width !== undefined && image.image.height !== undefined) {
      settings.width = image.image.width;
      settings.height = image.image.height;
    }
     */

    handleVariablesUpdate(settings);
  }, [canvasRef, bumpMapRef, image]);

  function updateBumpMapImage() {
    const bumpMapCanvas: HTMLCanvasElement | null = bumpMapRef.current;
    if (bumpMapCanvas !== null) {
      createImageFromCanvas(bumpMapCanvas).subscribe((bumpMapImage) => {
        setBumpMapImage(bumpMapImage);
      });
    }
  }

  function updateBitmaps() {
    const bumpMapCanvas = bumpMapRef.current;
    if (bumpMapCanvas !== null) {
      normalsGenerator(bumpMapCanvas, image.settings);
      updateBumpMapImage();
    }
  }

  function handleVariablesUpdate(
    incomingBumpMapSettings: BumpSettingsType
  ): void {
    image.settings = { ...incomingBumpMapSettings };
    setSettings({ ...image.settings });
    setBumpMapWidth(image.settings.width);
    setBumpMapHeight(image.settings.height);
    const bumpMapCanvas = bumpMapRef.current;
    if (bumpMapCanvas) {
      bumpMapCanvas.width = image.settings.width;
      bumpMapCanvas.height = image.settings.height;
    }
    updateBitmaps();
  }

  function handleExitClick() {
    sendMessage("EXIT_EDITOR", null);
  }

  return (
    <Container>
      <Row>
        <IconHolder onClick={handleExitClick}>
          <Icon name={"times rectangle"} />
        </IconHolder>
      </Row>
      <Row>
        <Column>
          <ImageEditControls
            updateCallback={handleVariablesUpdate}
            settings={image.settings}
          />
        </Column>
        <Column>
          <canvas ref={canvasRef} style={{ paddingBottom: "20px" }} />
          <GlassShader image={image.image} bumpMap={bumpMapImage} />
        </Column>
        <Column>
          <StyledCanvas
            ref={bumpMapRef}
            displayWidth={bumpMapWidth + "px"}
            displayHeight={bumpMapHeight + "px"}
          />
          <VisualSettings
            width={bumpMapWidth}
            height={bumpMapHeight}
            settings={settings}
          />
        </Column>
      </Row>
    </Container>
  );
};

export default ImageEditor;
