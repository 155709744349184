import { roundedRectangle } from "../../utils/roundedRect";
import { BumpSettingsType } from "../../types/types";
import QColor from "./QColor";

type QEasingCurve = {
  valueForProgress: (x: number) => number;
};

const linearEasing: QEasingCurve = {
  valueForProgress: (x: number) => x,
};

function getColorIndicesForCoord(
  x: number,
  y: number,
  width: number
): number[] {
  const red = 4 * (x + y * width);
  return [red, red + 1, red + 2, red + 3];
}

function setPixelColor(
  imageData: ImageData,
  x: number,
  y: number,
  color: QColor,
  imageWidth:number,
) {
  const indices = getColorIndicesForCoord(x, y, imageWidth);
  imageData.data[indices[0]] = color.red;
  imageData.data[indices[1]] = color.green;
  imageData.data[indices[2]] = color.blue;
  imageData.data[indices[3]] = color.alpha;
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

function calcCorrection(
  pos: number,
  max: number,
  r: number,
  ec: QEasingCurve,
  effect: number
): number {
  let ret = 0.0;
  if (pos < r) {
    const amount = (r - pos) / r;
    // Q_ASSERT (amount >= 0.0F && amount <= 1.0F);
    ret = ec.valueForProgress(amount) * effect;
  } else if (pos > max - r) {
    const amount = (pos - (max - r)) / r;
    // Q_ASSERT (amount >= 0.0F && amount <= 1.0F);
    ret = -ec.valueForProgress(amount) * effect;
  }
  return Math.round(ret);
}

function roundedCorners(
  canvas: HTMLCanvasElement,
  ctx2: CanvasRenderingContext2D,
  radiusX: number,
  radiusY: number
) {
  const ctx = canvas.getContext("2d");
  if (ctx === null) return;
  ctx.globalCompositeOperation = "destination-in";
  ctx.beginPath();
  ctx.fillStyle = "#ffffff";
  // TODO improve to allow X and Y corners
  roundedRectangle(ctx, 0, 0, canvas.width, canvas.height, radiusX);
  ctx.fill();
  ctx.closePath();
  ctx.globalCompositeOperation = "source-over";
}

export function normalsGenerator(
  canvas: HTMLCanvasElement,
  bumpSettings: BumpSettingsType
) {
  const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
  if (ctx === null) return;

  // m_image = QImage (bumpSettings.width, bumpSettings.height, QImage::Format_ARGB32);
  // m_image.fill (QColor (127, 127, 127));

  ctx.fillStyle = "#7F7F7F";
  ctx.fillRect(0, 0, bumpSettings.width, bumpSettings.height);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  /*
  const imageData = ctx.getImageData(
    0,
    0,
    bumpSettings.width,
    bumpSettings.height
  );
   */

  // QEasingCurve ec (QEasingCurve::Linear);
  const ec: QEasingCurve = linearEasing;

  for (let y = 0; y < bumpSettings.height; y++) {
    let vEffect = bumpSettings.topEffect;
    let vMargin = bumpSettings.topGlassMargin;
    if (y > bumpSettings.height * 0.5) {
      vEffect = bumpSettings.bottomEffect;
      vMargin = bumpSettings.bottomGlassMargin;
    }
    for (let x = 0; x < bumpSettings.width; x++) {
      let effect1 = bumpSettings.leftEffect1;
      let effect2 = bumpSettings.leftEffect2;
      let y1 = (bumpSettings.height * bumpSettings.leftY1) / 100;
      let y2 = (bumpSettings.height * bumpSettings.leftY2) / 100;
      let hMargin = bumpSettings.leftGlassMargin;
      if (x > bumpSettings.width * 0.5) {
        effect1 = bumpSettings.rightEffect1;
        effect2 = bumpSettings.rightEffect2;
        y1 = (bumpSettings.height * bumpSettings.rightY1) / 100;
        y2 = (bumpSettings.height * bumpSettings.rightY2) / 100;
        hMargin = bumpSettings.rightGlassMargin;
      }

      const col1 = new QColor();
      col1.setRed(
        clamp(
          calcCorrection(x, bumpSettings.width, hMargin, ec, effect1) + 127,
          0,
          255
        )
      );
      col1.setGreen(
        clamp(
          calcCorrection(y, bumpSettings.height, vMargin, ec, vEffect) + 127,
          0,
          255
        )
      );
      col1.setAlpha(255);
      col1.setBlue(0);

      const col2 = new QColor();
      col2.setRed(
        clamp(
          calcCorrection(x, bumpSettings.width, hMargin, ec, effect2) + 127,
          0,
          255
        )
      );
      col2.setGreen(
        clamp(
          calcCorrection(y, bumpSettings.height, vMargin, ec, vEffect) + 127,
          0,
          255
        )
      );
      col2.setAlpha(255);
      col2.setBlue(0);

      let col = col1;
      if (y < y1) {
        col = col1;
      } else if (y < y2) {
        // interpolate
        let t = (y - y1) / (y2 - y1);
        // Q_ASSERT(t >= 0.0F && t <= 1.0F);
        col.setRedF(col1.redF() * (1.0 - t) + col2.redF() * t);
        col.setGreenF(col1.greenF() * (1.0 - t) + col2.greenF() * t);
      } else {
        col = col2;
      }



      setPixelColor(imageData, x, y, col, canvas.width);
    }
  }
  ctx.putImageData(imageData, 0, 0);
  roundedCorners(
    canvas,
    ctx,
    bumpSettings.cornerRadiusX,
    bumpSettings.cornerRadiusY
  );
}
