import React, { FC, useContext, useEffect, useState, useRef } from "react";
import { Segment } from "semantic-ui-react";
import { Subscription } from "rxjs";

import State from "../state/AppState";
import Context from "../state/Context";
import ImageStrip from "./ImageStrip";
import {ImageData, StateMessageType, UIEventCallbackFunc} from "../types/types";

const unusedDragFunc = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

const ImageDropArea: FC = () => {
  const state: State | null = useContext(Context);
  const [imagesData, setImagesData] = useState<ImageData[]>([]);
  const uiEventCallbackRef = useRef<{ callback: null | UIEventCallbackFunc }>({
    callback: null,
  });

  const handleDrag = unusedDragFunc;
  const handleDragIn = unusedDragFunc;
  const handleDragOut = unusedDragFunc;

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    addFiles(e.dataTransfer.files);
  }

  function addFiles(files: FileList) {
    const imageType = /image.*/;
    let fileArray = Array.from(files).filter(
      (file: File) => file.type.match(imageType) !== null
    );
    if (state !== null) {
      state.message("ADD_FILES", fileArray);
    }
  }

  useEffect(() => {
    let subscription: null | Subscription = null;
    if (state !== null) {
      subscription = state.imageList.subscribe((incomingData) => {
        setImagesData([...incomingData]);
      });
      uiEventCallbackRef.current.callback = (
        type: StateMessageType,
        payload: any
      ): void => {
        state.message(type, payload);
      };
    }
    return () => {
      if (subscription !== null) {
        subscription.unsubscribe();
      }
    };
  }, [state]);

  return (
    <Segment
      onDrop={handleDrop}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
    >
      <ImageStrip
        imagesData={imagesData}
        callback={uiEventCallbackRef.current.callback}
      />
    </Segment>
  );
};

export default ImageDropArea;
