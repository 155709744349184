import React, { FC } from "react";
import styled from "styled-components";
import { ImageData, UIEventCallbackFunc } from "../types/types";
import ImageHolder from "./ImageHolder";

const Slide = styled.div`
  width: 100%;
  height: 200px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Message = styled.div`
  font-size: 32pt;
  color: lightgrey;
`

type ImageStripProps = {
  imagesData: ImageData[];
  callback: UIEventCallbackFunc | null;
};

const ImageStrip: FC<ImageStripProps> = ({ callback, imagesData }) => {
  const imageElements: (JSX.Element | null)[] = imagesData.map(
    (data: ImageData) => {
      const { image, file, id } = data;
      if (image === null) return null;
      if (callback === null) {
        callback = () => {};
      }
      return (
        <ImageHolder
          image={image}
          key={id}
          name={file.name}
          id={id}
          callback={callback}
        />
      );
    }
  );

  let message = null;
  if(imageElements.length < 1){
    message = <Message>Drag Images here!</Message>
  }

  return <Slide>{message}{imageElements}</Slide>;
};

export default ImageStrip;
