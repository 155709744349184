import React, { ReactElement, FC } from "react";
import styled from "styled-components";
import { BumpSettingsType } from "../types/types";
import LineMarking from "./LineMarking";

type VisualSettingsProps = {
  width: number;
  height: number;
  settings: BumpSettingsType;
};

const Container = styled.div`
  padding-top: 20px;
`;

const VisualSettings: FC<VisualSettingsProps> = ({
  width,
  height,
  settings,
}) => {
  const margin = 0;
  const fullWidth = width + 2 * margin;
  const fullHeight = height + 2 * margin;

  const markings: ReactElement[] = [];

  let v = settings.leftGlassMargin;
  let p = settings.leftGlassMargin + margin;
  markings.push(
    <LineMarking
      direction={"v"}
      color={"red"}
      value={v}
      position={p}
      margin={margin}
      width={width}
      height={height}
    />
  );

  v = settings.rightGlassMargin;
  p = width - settings.rightGlassMargin + margin;
  markings.push(
    <LineMarking
      direction={"v"}
      color={"red"}
      value={v}
      position={p}
      margin={margin}
      width={width}
      height={height}
    />
  );

  v = settings.topGlassMargin;
  p = settings.topGlassMargin + margin;
  markings.push(
    <LineMarking
      direction={"h"}
      color={"red"}
      value={v}
      position={p}
      margin={margin}
      width={width}
      height={height}
    />
  );

  v = settings.bottomGlassMargin;
  p = height - settings.bottomGlassMargin + margin;
  markings.push(
    <LineMarking
      direction={"h"}
      color={"red"}
      value={v}
      position={p}
      margin={margin}
      width={width}
      height={height}
    />
  );

  let x1 = margin;
  let x2 = settings.leftGlassMargin + margin;
  let y1 = (settings.leftY1 / 100) * height + margin;
  let y2 = y1;
  markings.push(
    <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={"blue"} strokeWidth={1} />
  );

  y1 = (settings.leftY2 / 100) * height + margin;
  y2 = y1;
  markings.push(
    <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={"blue"} strokeWidth={1} />
  );

  x1 = margin + width - settings.rightGlassMargin;
  x2 = width;
  y1 = (settings.rightY1 / 100) * height + margin;
  y2 = y1;
  markings.push(
    <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={"blue"} strokeWidth={1} />
  );

  x1 = margin + width - settings.rightGlassMargin;
  x2 = width;
  y1 = (settings.rightY2 / 100) * height + margin;
  y2 = y1;
  markings.push(
    <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={"blue"} strokeWidth={1} />
  );

  return (
    <Container>
      <svg width={fullWidth} height={fullHeight}>
        <rect
          x={margin}
          y={margin}
          width={width}
          height={height}
          fill={"lightgray"}
        />
        {markings}
      </svg>
    </Container>
  );
};

export default VisualSettings;
