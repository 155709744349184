import React from "react";

type LineMarkingProps = {
  direction: "h" | "v";
  color: string;
  value: number;
  position: number;
  margin: number;
  width: number;
  height: number;
};

const LineMarking: React.FC<LineMarkingProps> = ({
  direction,
  color,
  value,
  position,
  margin,
  width,
  height,
}) => {
  const line =
    direction === "h" ? (
      <line
        x1={0}
        x2={2 * margin + width}
        y1={position}
        y2={position}
        stroke={color}
        strokeWidth={1}
      />
    ) : (
      <line
        y1={0}
        y2={2 * margin + height}
        x1={position}
        x2={position}
        stroke={color}
        strokeWidth={1}
      />
    );

  return <g>{line}</g>;
};

export default LineMarking;
