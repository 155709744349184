import { BumpSettingsType } from "../types/types";

export type InputType = {
  variable: string;
  label?: string;
  default: number;
};

export type InputGroup = {
  group: string;
  inputs: InputType[];
};

export const bumpMapVariables: InputGroup[] = [
  {
    group: "Output",
    inputs: [
      { variable: "width", default: 462 },
      { variable: "height", default: 274 },
    ],
  },
  {
    group: "Margins",
    inputs: [
      { variable: "leftGlassMargin", label: "left", default: 8 },
      { variable: "rightGlassMargin", label: "right", default: 8 },
      { variable: "topGlassMargin", label: "top", default: 7 },
      { variable: "bottomGlassMargin", label: "bottom", default: 7 },
    ],
  },
  {
    group: "Effects",
    inputs: [
      { variable: "topEffect", default: 24 },
      { variable: "bottomEffect", default: 18 },
    ],
  },
  {
    group: "Left Effects",
    inputs: [
      { variable: "leftY1", label: "Top %", default: 30 },
      { variable: "leftY2", label: "Bottom %", default: 61 },
      { variable: "leftEffect1", label: "Effect Top", default: 40 },
      { variable: "leftEffect2", label: "Effect Bottom", default: 0 },
    ],
  },
  {
    group: "Right Effects",
    inputs: [
      { variable: "rightY1", label: "Top %", default: 30 },
      { variable: "rightY2", label: "Bottom %", default: 70 },
      { variable: "rightEffect1", label: "Effect Top", default: 40 },
      { variable: "rightEffect2", label: "Effect Bottom", default: 10 },
    ],
  },
  {
    group: "Corner",
    inputs: [
      { variable: "cornerRadiusX", label: "corner radius", default: 12 },
    ],
  },
];

export const getBumpMapDefaults = (): BumpSettingsType => {
  const defaults: any = {
    bottomEffect: 0,
    bottomGlassMargin: 0,
    height: 0,
    leftEffect1: 0,
    leftEffect2: 0,
    leftGlassMargin: 0,
    leftY1: 0,
    leftY2: 0,
    rightEffect1: 0,
    rightEffect2: 0,
    rightGlassMargin: 0,
    rightY1: 0,
    rightY2: 0,
    topEffect: 0,
    topGlassMargin: 0,
    width: 0,
  };
  bumpMapVariables.forEach((group: InputGroup) => {
    group.inputs.forEach((input) => {
      if (typeof defaults[input.variable] !== undefined)
        defaults[input.variable] = input.default;
    });
  });

  return defaults as BumpSettingsType;
};
