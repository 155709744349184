// Much of this code from:
// https://webglfundamentals.org/webgl/lessons/webgl-2-textures.html
//

import { createProgram, createShader } from "./shaderUtils";
import { vertexShaderSrc } from "./vertexShader";
import { fragmentShaderSrc } from "./fragmentShader";

export function render(
  canvas: HTMLCanvasElement,
  image1: HTMLImageElement,
  image2: HTMLImageElement
) {
  const images = [image1, image2];
  const gl: WebGLRenderingContext | null = canvas.getContext("webgl", {
    preserveDrawingBuffer: true,
  });
  if (!gl) {
    return;
  }

  // Create shaders
  const vertexShader: WebGLShader | undefined = createShader(
    gl,
    gl.VERTEX_SHADER,
    vertexShaderSrc
  );
  const fragmentShader: WebGLShader | undefined = createShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSrc
  );

  if (vertexShader === undefined || fragmentShader === undefined) return;

  // setup GLSL program
  const program = createProgram(gl, vertexShader, fragmentShader);
  if (program === null) {
    return;
  }

  gl.useProgram(program);

  // look up where the vertex data needs to go.
  const positionLocation: number = gl.getAttribLocation(program, "a_position");
  const texcoordLocation: number = gl.getAttribLocation(program, "a_texCoord");
  const auxLocation:number = gl.getAttribLocation(program, "a_aux");

  // Create a buffer to put three 2d clip space points in
  const positionBuffer = gl.createBuffer();

  // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  // Set a rectangle the same size as the image.
  setRectangle(gl, 0, 0, images[0].width, images[0].height);

  // provide texture coordinates for the rectangle.
  const texcoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      0.0,
      0.0,
      1.0,
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      1.0,
    ]),
    gl.STATIC_DRAW
  );

  const auxBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, auxBuffer);
  gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([
        0.0,
        0.0,
        1.0,
        0.0,
        0.0,
        1.0,
        0.0,
        1.0,
        1.0,
        0.0,
        1.0,
        1.0,
      ]),
      gl.STATIC_DRAW
  );

  // create 2 textures
  const textures = [];
  for (let ii = 0; ii < 2; ++ii) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Set the parameters so we can render any size image.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    // Upload the image into the texture.
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      images[ii]
    );

    // add the texture to the array of textures.
    textures.push(texture);
  }

  // lookup uniforms
  const resolutionLocation = gl.getUniformLocation(program, "u_resolution");

  // lookup the sampler locations.
  const u_image0Location = gl.getUniformLocation(program, "uImageTexture");
  const u_image1Location = gl.getUniformLocation(program, "uBumpTexture");

  // todo delete the need for this line
  // webglUtils.resizeCanvasToDisplaySize(gl.canvas);

  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

  // Clear the canvas
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  // Tell it to use our program (pair of shaders)
  gl.useProgram(program);

  // Turn on the position attribute
  gl.enableVertexAttribArray(positionLocation);

  // Bind the position buffer.
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  {
    // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    const size = 2; // 2 components per iteration
    const type = gl.FLOAT; // the data is 32bit floats
    const normalize = false; // don't normalize the data
    const stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    const offset = 0; // start at the beginning of the buffer
    gl.vertexAttribPointer(
      positionLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );
  }

  // Turn on the texcoord attribute
  gl.enableVertexAttribArray(texcoordLocation);

  // bind the texcoord buffer.
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

  {
    // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
    const size = 2; // 2 components per iteration
    const type = gl.FLOAT; // the data is 32bit floats
    const normalize = false; // don't normalize the data
    const stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    const offset = 0; // start at the beginning of the buffer
    gl.vertexAttribPointer(
      texcoordLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );
  }


  // Turn on the aux attribute
  gl.enableVertexAttribArray(auxLocation);

  // bind the aux buffer.
  gl.bindBuffer(gl.ARRAY_BUFFER, auxBuffer);

  {
    // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
    const size = 2; // 2 components per iteration
    const type = gl.FLOAT; // the data is 32bit floats
    const normalize = false; // don't normalize the data
    const stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    const offset = 0; // start at the beginning of the buffer
    gl.vertexAttribPointer(
        auxLocation,
        size,
        type,
        normalize,
        stride,
        offset
    );
  }








  // set the resolution
  gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);

  // set which texture units to render with.
  gl.uniform1i(u_image0Location, 0); // texture unit 0
  gl.uniform1i(u_image1Location, 1); // texture unit 1

  // Set each texture unit to use a particular texture.
  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, textures[0]);
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, textures[1]);

  // Draw the rectangle.
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

//function randomInt(range) {
//    return Math.floor(Math.random() * range);
//}

function setRectangle(
  gl: WebGLRenderingContext,
  x: number,
  y: number,
  width: number,
  height: number
) {
  const x1 = x;
  const x2 = x + width;
  const y1 = y;
  const y2 = y + height;
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]),
    gl.STATIC_DRAW
  );
}
