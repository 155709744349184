import React, { useContext, useState, useEffect } from "react";
import { Subscription } from "rxjs";

import Context from "../state/Context";
import ImageDropArea from "./ImageDropArea";
import ImageEditorController from "./ImageEditorController";
import { ImageDataOrNull } from "../types/types";

const UISwitcher = () => {
  const state = useContext(Context);
  const [editImage, setEditImage] = useState<ImageDataOrNull>(null);

  useEffect(() => {
    let subscription: Subscription | null = null;
    if (state !== null) {
      subscription = state.editableImage.subscribe((image) => {
        setEditImage(image);
      });
    }
    return () => {
      if (subscription !== null) {
        subscription.unsubscribe();
        subscription = null;
      }
    };
  }, [state]);

  return (
    <div>
      {editImage === null ? (
        <ImageDropArea />
      ) : (
        <ImageEditorController image={editImage} />
      )}
    </div>
  );
};
export default UISwitcher;
