import React from "react";

import StateContext from "./state/Context";
import AppState from "./state/AppState";
import UISwitcher from "./components/UISwitcher";
import { fetchData } from "./services/fetchImage/fetchImage";

const state = new AppState();

function App() {
  fetchData();
  return (
    <StateContext.Provider value={state}>
      <UISwitcher />
    </StateContext.Provider>
  );
}

export default App;
