class QColor {
  private _red = 0;
  private _green = 0;
  private _blue = 0;
  private _alpha = 0;

  get red() {
    return this._red;
  }
  get green() {
    return this._green;
  }
  get blue() {
    return this._blue;
  }
  get alpha() {
    return this._alpha;
  }
  setRed(r: number) {
    this._red = r;
  }
  setGreen(g: number) {
    this._green = g;
  }
  setBlue(b: number) {
    this._blue = b;
  }
  setRedF(n: number) {
    this._red = Math.round(n * 255);
  }
  setGreenF(n: number) {
    this._green = Math.round(n * 255);
  }
  setBlueF(n: number) {
    this._blue = Math.round(n * 255);
  }
  setAlpha(n: number) {
    this._alpha = n;
  }
  redF() {
    return this._red / 255;
  }
  greenF() {
    return this._green / 255;
  }
}

export default QColor;
